@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700'); // feel free to change this font type at any momment.

$primary: #666; /* MAIN COLOR */
$secondary: #484d5b; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #d5d5d5;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {

	background: url(../img/diagmonds-light.png);
	font-size: 1.5em;

	h1,h2,h3,h4,h5,h6 {
		font-family: 'Squada One', cursive;
		font-size:2em;
	}
}

@import url('https://fonts.googleapis.com/css?family=Squada+One');
// font-family: 'Squada One', cursive;

nav {
	background: url(../img/diagmonds-light.png);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	z-index: 10;
}
.navbar-brand {

    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
	> li > a {
			border-radius: 5px;
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
	    	background: $primary;
	    	color:$wht;

		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
	    	}

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.5em 1em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk,20%);
		text-decoration: none;

	}
}

.btn-default {
	@include btn;
}
// mixins end here

// sections start
.section-a {
	padding: 60px;
	margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'
}
// sections end

.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: #fff;
	font-size: 15px;
	
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}
		
		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}		
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}	
}

.logo {
	max-width: 300px;
	// padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

i.fa {
	
	color: #fff;
	font-size: 100px;	
} 

i.seo {
	transform: rotate(235deg);
	padding:151px 0px 0px;
}

i.rocket{
	transform: rotate(242deg);
	padding: 135px 0px 0px;
}


i.code{
	transform: rotate(250deg);
	padding: 103px 0px 0px;
}

i.social{
	transform: rotate(250deg);
	padding: 103px 0px 0px;
}

.desc{
	padding: 0px 0px;

	@media (max-width: 767px){
		padding: 40px 0px;
	}
}

.intro {
	// background:url(../img/banner.jpg);
	// background-repeat: no-repeat;
	// background-size: cover;
	// background-position: 50% 50%;
	// padding: 250px 0px;
}

.divider {
	//background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/header_bg.png);
	// background-image: url(../img/banner.jpg);
	background-repeat: no-repeat;
	background-size: contain;
	padding: 0px 0pc;
	overflow: hidden;
}

.overlay{

   background:url(../img/banner.jpg);
   background-color: #834d33;
   height: 500px;
   // margin-bottom: -50px;
  -webkit-animation:100s scroll infinite linear;
  -moz-animation:100s scroll infinite linear;
  -o-animation:100s scroll infinite linear;
  -ms-animation:100s scroll infinite linear;
  animation:100s scroll infinite linear;
  overflow: hidden;
}

@-webkit-keyframes scroll{
  100%{
    background-position: 2099px 0px;
  }
}

@-moz-keyframes scroll{
  100%{
    background-position: 2099px 0px;
  }
}

@-o-keyframes scroll{
  100%{
    background-position: 2099px 0px;
  }
}

@-ms-keyframes scroll{
  100%{
   background-position: 2099px 0px;
  }
}

@keyframes scroll{
  100%{
    background-position: 2099px 0px;
  }
}

.features {
	max-width: 1200px;
	margin: 0px auto;
	padding: 50px 0px;
}

.featbox {
	padding: 75px 0px;
	@media (max-width: 767px){
		padding: 0px 0px;
	}
}

.oval {
    width: 220px;
    height: 160px;
    background: #085ca7;
    border-radius: 100%;
   	transform: rotate(115deg);
   	overflow: hidden;

	
	@media (max-width: 767px) {
		margin-left: 33%;
    	padding: 0px 0px;
	}

	@media (max-width: 520px) {
		margin-left: 22%;
    	padding: 0px 0px;
	}

	@media (max-width: 450px) {
		margin-left: 22%;
    	padding: 0px 0px;
	}

	@media (max-width: 320px) {
		margin-left: 23px;
    	padding: 0px 0px;
	}
}

.about {
	padding: 50px 0px;

	@media (max-width: 767px) {
			padding: 10px 0px;
	}
}

.contact {
	background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(../img/banner2.jpg);
	// background:url(../img/banner2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	padding: 250px 0px;


	@media (max-width:767px){
		padding: 75px 0px;
	}

	@media (max-width:568px) and (orientation:landscape){
		padding: 50px 0px;
	}

	h1, h2, a{
		color: #fff;
		text-decoration: none;
		font-size: 25px;
	}

	hr {
	 width: 100px;
	}

	.pos{
		// margin-top:-250px;
	}
}